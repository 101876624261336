<template>
  <div>
    <div class="tips"  v-if="result.length === 0">
      <img src="./assets/logo.png" >
    </div>
    <van-search
            class="searchBar"
            v-model="orderNum"
            @search="onSearch"
            placeholder="请输入订单号"
    ></van-search>

    <div v-if="result.length > 0">
      <div class="list">
        <div class="item" v-for="(item, index) in result" :key="index">
          {{item.carrierCode}} : {{item.expressNo}} <img class="copy" src="./assets/copy.png" @click="onCopy(index)">
        </div>
      </div>
      <div class="logisticsList" v-if="logistics.length > 0">
        <div class="item" v-for="(item, index) in logistics" :key="index">

          <div class="content">
            <div class="icon">
              <img v-if="index == 0" src="./assets/doc.png">
              <img v-else src="./assets/doc2.png">
            </div>
            <div class="info"><span class="time">{{item.uploadTime}}</span> <span class="process">{{item.processInfo}}</span></div>
          </div>
        </div>
      </div>
      <div class="logisticsList" v-else>
        <div class="defaultText">暂无物流信息</div>
      </div>
    </div>

    <input class="copyText" type="text" v-model="copyText" ref="copyText">

    <div class="seartchBtn" :style="{ marginTop: result.length === 0 ? '30vh' : 0}"><van-button class="btn" type="danger" round color="#e92128" @click="onSearchBtn">搜索</van-button></div>
  </div>
</template>

<script>
  import axios from "axios"
  // import { ref, onMounted } from 'vue';
  import { Toast } from 'vant';
export default {
  name: 'App',
  data() {
    return{
      value: 'M1A0350_0006184',
      orderNum: "",
      result: [],
      logistics: [],
      list: [1,2],
      loading: false,
      finished: false,
      copyText: ""
    }
  },
  methods: {
    onCopy(index) {
      this.copyText = this.result[index].expressNo
      this.$refs.copyText.select()
      if (document.execCommand('copy') ===  true) {
        Toast("复制成功")
      }
    },
    onSearch(val) {
      this.queryRetrievalByCustomer(val)
    },
    onSearchBtn() {
     this.queryRetrievalByCustomer(this.orderNum)
    },
    queryRetrievalByCustomer(orderNum) {
      if (!orderNum) {
        Toast("请输入订单号")
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      let that = this
      let data = "customerCode=" + orderNum
      axios.post('./rubicware/rf/retrievalByCustomer',data,
              {  headers: {'Request-By': 'Rf'} })
              .then(function (response) {
                Toast.clear()
                let res = response.data
                if (res.errCode) {
                  Toast(res.msg)
                  return
                }
                that.result = []
                that.result.push(res.res)
                that.queryLogistices(res.res)
              })
              .catch(function (error) {
                Toast.clear()
                Toast(error)
              });
    },
    queryLogistices(orderData) {
      let that = this
      orderData
      let data = "carrierCode=YTO&expressNo=" + orderData.expressNo
      // let data = "carrierCode=YTO" + "&expressNo=YT2131709021398"
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
        axios.post('./tmsapi/queryLogistices',data,
              {  headers: {
                  'appkey':'shbyron',
                  'token':'e66121baf44b2299851f011497bdf318',
                  'Content-Type':'application/x-www-form-urlencoded',
                  'datetime':'20190731',
                  'sign':'D75C0A790229A50B35FDFB38A7AA3E66'
                 }})
              .then(function (response) {
                Toast.clear()
                that.logistics = response.data.res.reverse()
              })
              .catch(function (error) {
                Toast.clear()
                Toast(error)
              });
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.searchBar{
  /*width: 100%;*/
  /*position: fixed;*/
  /*left: 0;*/
  /*bottom: 0;*/
  /*margin-top: 20px;*/
}
  .tips{
    margin-top: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #999999;
    margin-bottom: 20px;
  }
  .tips > img{
    width: 50%;
    margin-bottom: 10px;
  }
  .list{
    padding: 0 10px;
  }
.list > .item{
    width: 100%;
    height: 100px;
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 20px;
  }
.list > .item > .copy{
  width: 20px;
  height: 20px;
  padding: 0 5px;
}

  .logisticsList{
    height: 60vh;
    overflow-y: scroll;
  }

  .defaultText{
    width: 100%;
    text-align: center;
    color: #999999;
    font-size: 14px;
    margin-top: 28vh;
  }


.logisticsList > .item{
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
}

.logisticsList > .item > .content{
  display: flex;
  flex-direction: row;
}
.logisticsList > .item > .content > .icon{
  width: 20px;
  height: 20px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}
.logisticsList > .item > .content > .icon > img{
  width: 20px;
  height: 20px;
}

.logisticsList > .item > .content > .info:before{
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -25px;
  width: 1px;
  height: 100%;
  background-color: #cacaca;
  z-index: 0;
}
.logisticsList > .item:first-child > .content > .info:before{
  height: 80%;
  background-color: #f7b2b4;
}
.logisticsList > .item:first-child > .content  > .info{
  padding-top: 0;
  margin-top: 20px;
}
.logisticsList > .item > .content  > .info{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  position: relative;
  padding-top: 20px;
}
.logisticsList > .item > .content  > .info > .time{
  font-size: 12px;
}
.logisticsList > .item > .content  > .info > .process{
  font-size: 14px;
}
  .copyText{
    /*width: 0;*/
    /*height: 0;*/
    position: fixed;
    top: 0;
    /*left:0px;*/
    left: -100000px;
  }
  .seartchBtn{
    /*margin-top: 20px;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
.seartchBtn .btn{
  width: 60%;
}
</style>
